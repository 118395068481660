export default class home {

	constructor() {

		this.isMobile = window.matchMedia('(max-width: 767px)').matches;
		
		this.initializePage();
		if(document.body.dataset.template !== 'pages/page'){
			this.initializeState();
			this.disableInitialInterference();
			this.initializeReadMore();
			this.initializeTestimonials();
			this.initializeIntersectionObserver();
			this.initializeMenuButtons();
			setTimeout(() => {
				this.initializeLayerPeeks();
				this.initializeLayerClicks();
			}, 2000);
		}
		
		this.initializeMenu();
		this.handleFocusStates();
		

		if(this.isMobile){
			this.initializeMobile();
		}

	}

	initializeMobile() {
		this.handlePopState();
		this.showHideMenuButton();
		this.startTicker();

		document.body.classList.add('mobile');
		window.addEventListener('scroll', () => {
			console.log(window.scrollTop);
			if(document.body.scrollTop > 0){
				document.body.classList.add('scrolled');
			} else {
				document.body.classList.remove('scrolled');
			}
		});

		
	}

	showHideMenuButton() {
		window.addEventListener('scroll', () => {
			const menuButton = document.querySelector('.menu-button');
			const mainElement = document.querySelector('main');
			if (!menuButton || !mainElement) {
				return;
			}
			const menuButtonBottom = menuButton.getBoundingClientRect().bottom;
			const mainTop = mainElement.getBoundingClientRect().top;
			const mainPaddingTop = parseInt(getComputedStyle(mainElement).paddingTop, 10);
		
			if (window.scrollY >= 65) {
				document.body.classList.add('foreground-menu-button');
			} else {
				document.body.classList.remove('foreground-menu-button');
			}
		});
	}

	startTicker() {
		const tickerElement = document.querySelector('.news-footer-inner');

		if (!tickerElement || tickerElement.getBoundingClientRect().width < window.innerWidth - 40) {
			tickerElement.parentElement.classList.add('hide-gradients');
			return;
		}
		// Duplicate the content
		const originalContent = tickerElement.innerHTML;
		tickerElement.innerHTML = originalContent + originalContent;

		let xPos = 0;
		const speed = 1; // Speed of the ticker

		const moveTicker = () => {
			// Use firstElementChild for accurate width measurement
			// Width is now the width of the original content times 2
			const contentWidth = tickerElement.firstElementChild.getBoundingClientRect().width * 2;

			// Adjust the x-position
			xPos -= speed;

			// Check if half of the content has moved out of view, then reset
			if (-xPos > contentWidth / 2) {
				xPos += contentWidth / 2;
			}

			// Apply the transform
			tickerElement.style.transform = `translateX(${xPos}px)`;
		};

    	// Set the interval for moving the ticker
		setTimeout(() => {
			if(tickerElement.getBoundingClientRect().width >= document.querySelector('.intro-container footer').getBoundingClientRect().width){
    			setInterval(moveTicker, 30);
			}
		}, 1100);
	}


	initializeState() {
		if(!this.isMobile){
			const homeUrl = document.body.getAttribute('data-home');
			history.replaceState(null, null, homeUrl);
		}
	}

	updateAddressBar(url, title = 'Khift', replace = false) {
		if (typeof history.pushState === 'function') {
			if(replace){
				history.replaceState({}, title, url);
			} else {
				history.pushState({}, title, url);
			}
		}
	}

	handlePopState() {
		history.replaceState({page: "initialPage"}, "Initial Page", window.location.href);

		window.addEventListener('popstate', function(event) {
			if (event.state) {
			  	window.location.href = window.location.href; // Fully reload the page
			}
		});

		window.onpageshow = function(event) {
			if (event.persisted) {
				window.location.reload() 
			}
		};
	}

	initializeReadMore() {
		document.querySelectorAll('.more').forEach((element) => {
			const previousElement = element.previousElementSibling;
			if (previousElement) { // Make sure there is a previous element
			  	previousElement.insertAdjacentHTML('beforeend', '<span class="more-dots">..</span> <button class="show-more" aria-label="Expand this text to read more">read more</button>');
			}
		});

		document.querySelectorAll('.show-more').forEach((element) => {
		
			element.addEventListener('click', (e) => {
				const section = e.target.closest('section');

				section.querySelector('.more-dots').classList.add('hide-more-prompt');
				section.querySelector('.show-more').classList.add('hide-more-prompt');

				const moreHeight = this.getActualHeight(section.querySelector('.more'));

				setTimeout(() => {
					section.querySelector('.more').style.height = `${moreHeight}px`;
					setTimeout(() => {
						section.querySelector('.more').style.opacity = 1;
					}, 200);
				}, 100);

				e.preventDefault();
			});
		});
	}

	initializeTestimonials() {
		document.querySelectorAll('.testimonials .name').forEach((element) => {
			const previousElement = element.previousElementSibling;
			if (previousElement) {
			  	previousElement.insertAdjacentHTML('beforeend', '<span class="close-quote">”</span>');
			}
		});
	}

	initializeMenu() {
		// Menu Button
		document.querySelector('.menu-button').addEventListener('click', (e) => {
			document.body.classList.toggle('menu-open');
			let isOpen = document.body.classList.contains('menu-open');
			e.target.setAttribute('aria-expanded', isOpen);
			let menu = document.querySelector('.menu');
			menu.setAttribute('aria-hidden', !isOpen);
			
			if (isOpen && !this.isMobile) {
				document.body.classList.remove('layer-1-open', 'layer-2-open', 'layer-3-open', 'layer-4-open');
			}
		  
			e.target.focus();

		});
		  
		// Page Cover
		document.querySelector('.page-cover').addEventListener('click', () => {
			document.body.classList.remove('menu-open');
		});

		
	}

	initializeMenuButtons() {
		// Menu Items
		document.querySelector('.menu-layer-1').addEventListener('click', (e) => {
			
			document.body.classList.remove('layer-1-open', 'layer-2-open', 'layer-3-open', 'layer-4-open', 'menu-open');
			document.body.classList.add('layer-1-open');

			document.querySelector('.layer-1').setAttribute('aria-pressed', 'true');

			if(!this.isMobile){
				document.querySelector('.layer-1').focus();
			} else {
				window.scrollTo(0, 0);
				this.updateAddressBar(e.target.getAttribute('href'));
			}

			e.preventDefault();
		});

		document.querySelector('.menu-layer-2').addEventListener('click', (e) => {
			document.body.classList.remove('layer-1-open', 'layer-2-open', 'layer-3-open', 'layer-4-open', 'menu-open');
			document.body.classList.add('layer-2-open');

			document.querySelector('.layer-2').setAttribute('aria-pressed', 'true');
			if(!this.isMobile){
				document.querySelector('.layer-2').focus();
			} else {
				window.scrollTo(0, 0);
				this.updateAddressBar(e.target.getAttribute('href'));
			}

			e.preventDefault();
		});

		document.querySelector('.menu-layer-3').addEventListener('click', (e) => {
			document.body.classList.remove('layer-1-open', 'layer-2-open', 'layer-3-open', 'layer-4-open', 'menu-open');
			document.body.classList.add('layer-3-open');

			document.querySelector('.layer-3').setAttribute('aria-pressed', 'true');
			if(!this.isMobile){
				document.querySelector('.layer-3').focus();
			} else {
				window.scrollTo(0, 0);
				this.updateAddressBar(e.target.getAttribute('href'));
			}

			e.preventDefault();
		});

		document.querySelector('.menu-layer-4').addEventListener('click', (e) => {
			document.body.classList.remove('layer-1-open', 'layer-2-open', 'layer-3-open', 'layer-4-open', 'menu-open');
			document.body.classList.add('layer-4-open');

			document.querySelector('.layer-4').setAttribute('aria-pressed', 'true');
			if(!this.isMobile){
				document.querySelector('.layer-4').focus();
			} else {
				window.scrollTo(0, 0);
				this.updateAddressBar(e.target.getAttribute('href'));
			}

			e.preventDefault();
		});
	}

	initializeLayerPeeks() {
		document.querySelector('.layer-1').addEventListener('mouseenter', (e) => {
			document.body.classList.add('layer-1-peek');
		});
		
		document.querySelector('.layer-1').addEventListener('mouseleave', (e) => {
			document.body.classList.remove('layer-1-peek');
		});

		document.querySelector('.layer-2').addEventListener('mouseenter', (e) => {
			document.body.classList.add('layer-2-peek');
		})
		
		document.querySelector('.layer-2').addEventListener('mouseleave', (e) => {
			document.body.classList.remove('layer-2-peek');
		});
	
		document.querySelector('.layer-3').addEventListener('mouseenter', (e) => {
			document.body.classList.add('layer-3-peek');
		})
		
		document.querySelector('.layer-3').addEventListener('mouseleave', (e) => {
			document.body.classList.remove('layer-3-peek');
		});

		document.querySelector('.layer-4').addEventListener('mouseenter', (e) => {
			document.body.classList.add('layer-4-peek');
		})
		
		document.querySelector('.layer-4').addEventListener('mouseleave', (e) => {
			document.body.classList.remove('layer-4-peek');
		});
	}

	initializeLayerClicks() {
		document.querySelector('.intro-container').addEventListener('click', function(e) {
			document.body.classList.remove('layer-1-open', 'layer-2-open', 'layer-3-open', 'layer-4-open');
			this.setAttribute('aria-pressed', 'false');
		});
		  
		document.querySelector('.intro-container').addEventListener('keydown', function(e) {
			if (e.key === 'Enter' || e.keyCode === 13) {
			  this.click();
			}
		});

		document.querySelector('.layer-1').addEventListener('click', function(e) {
			document.body.classList.remove('layer-1-open', 'layer-2-open', 'layer-3-open', 'layer-4-open');
			document.body.classList.add('layer-1-open');

			this.setAttribute('aria-pressed', 'true');
		});

		document.querySelector('.layer-1').addEventListener('keydown', function(e) {
			if (e.key === 'Enter' || e.keyCode === 13) {
				this.click();
			}
		});

		document.querySelector('.layer-2').addEventListener('click', function(e) {
			document.body.classList.remove('layer-1-open', 'layer-2-open', 'layer-3-open', 'layer-4-open');
			document.body.classList.add('layer-2-open');

			this.setAttribute('aria-pressed', 'true');
		});

		document.querySelector('.layer-2').addEventListener('keydown', function(e) {
			if (e.key === 'Enter' || e.keyCode === 13) {
				this.click();
			}
		});

		document.querySelector('.layer-3').addEventListener('click', function(e) {
			document.body.classList.remove('layer-1-open', 'layer-2-open', 'layer-3-open', 'layer-4-open');
			document.body.classList.add('layer-3-open');

			this.setAttribute('aria-pressed', 'true');
		});

		document.querySelector('.layer-3').addEventListener('keydown', function(e) {
			if (e.key === 'Enter' || e.keyCode === 13) {
				this.click();
			}
		});

		document.querySelector('.layer-4').addEventListener('click', function(e) {
			document.body.classList.remove('layer-1-open', 'layer-2-open', 'layer-3-open', 'layer-4-open');
			document.body.classList.add('layer-4-open');

			this.setAttribute('aria-pressed', 'true');
		});

		document.querySelector('.layer-4').addEventListener('keydown', function(e) {
			if (e.key === 'Enter' || e.keyCode === 13) {
				this.click();
			}
		});
	}

	handleFocusStates() {
		function handleMouseDown(e) {
			document.body.classList.add('no-outline');
		}
		
		function handleKeyDown(e) {
			if (e.key === 'Tab') {
				document.body.classList.remove('no-outline');
			}
		}
		
		document.addEventListener('mousedown', handleMouseDown);
		document.addEventListener('keydown', handleKeyDown);
	}

	initializePage() {

		setTimeout(() => {
			document.body.classList.add('show-footer');
		}, 1000);

	}

	initializeIntersectionObserver() {
		const observer = new IntersectionObserver(
			(entries) => {
			entries.forEach((entry) => {
				const viewportHeight = window.innerHeight;
				const { top, bottom } = entry.boundingClientRect;
		
				// Check only Y-axis
				if (bottom > 0 && top < viewportHeight) {
					entry.target.classList.remove("out-of-view");

					this.updatePrompts(entry);
				} else {
					entry.target.classList.add("out-of-view");
				}
			});
			}, {
				root: null,
				threshold: [0, 0.5, 1],
				rootMargin: '0px'
			}
		);
		
		// document.querySelectorAll("ul:not(.column) li:not(.menu-item), .check, .intro-slide").
		
		document.querySelectorAll(".intro-slide").forEach((element) => {
			observer.observe(element);
		});
	}

	getActualHeight(element) {
		const originalOverflow = element.style.overflow;
		const originalHeight = element.style.height;
		element.style.overflow = 'visible';
		element.style.height = 'auto';
		const scrollHeight = element.scrollHeight;
		element.style.overflow = originalOverflow;
		element.style.height = originalHeight;
		return scrollHeight;
	}

	disableInitialInterference() {
		document.body.classList.add('initial-interference-disabled');
		setTimeout(() => {
			document.body.classList.remove('initial-interference-disabled');
		}, 2000);
	}

	updatePrompts(entry) {
		if (entry.target.classList.contains('intro-slide')) {
			
			const slide = entry.target;
			const element = slide.querySelector('.question');
			const text = element.textContent;
			const indicators = document.querySelectorAll('.indicators li');
			const slideIndex = Array.from(slide.parentNode.children).indexOf(slide);

			if(!entry.target.classList.contains('slide-typed')){
				slide.classList.add('slide-typed');

				this.typeOutQuestion(element, text, () => {
					document.body.classList.add('first-question-typed');
					this.typeOutAnswer(slide.querySelector('.answer'), 80, 1.1);
				});
			}

			indicators.forEach((indicator) => {
				indicator.classList.remove('indicator-active');
				indicators[slideIndex].classList.add('indicator-active');
			});
			
		}
	}

	typeOutQuestion(element, text, callback) {
		element.innerHTML = '';
		const words = text.split(' ');
	
		let totalDuration = 0;
		element.style.opacity = '1';
	
		words.forEach((word, index) => {
			const span = document.createElement('span');
			span.textContent = `${word} `;
			span.style.opacity = '0';
			element.appendChild(span);
	
			let delay = 100;
	
			const fromLastIndex = words.length - index - 1;
			if (fromLastIndex === 2) {
				delay = 150;
			} else if (fromLastIndex === 1) {
				delay = 220;
			} else if (fromLastIndex === 0) {
				delay = 320;
			}

			totalDuration += delay;
	
			setTimeout(() => {
				span.style.opacity = '1';
			}, totalDuration);
		});
	
		if (callback) {
			setTimeout(callback, totalDuration + 1000);
		}
	}
	

	typeOutAnswer(element, initialDelay, easeOutFactor) {
		let totalDuration = 0;

		// Remove all <p> tags inside the element
		Array.from(element.getElementsByTagName('p')).forEach(p => {
			// Move all children of the <p> up to the parent node
			while (p.firstChild) {
			p.parentNode.insertBefore(p.firstChild, p);
			}
			// Remove the empty <p> tag
			p.remove();
		});
	  
		// Temporarily hold new elements
		const newElements = [];
	  
		Array.from(element.childNodes).forEach((child) => {
		  if (child.nodeType === 3) { // Text node
			const words = child.nodeValue.split(' ').filter(word => word.trim() !== '');
			words.forEach((word) => {
			  const span = document.createElement('span');
			  span.textContent = word + ' ';
			  span.style.opacity = '0';
			  newElements.push(span);
			});
		  } else if (child.tagName === 'EM') { // Keeper span
			const span = document.createElement('span');
			span.textContent = child.textContent + ' ';
			span.classList.add('keeper');
			newElements.push(span);
			span.style.opacity = '0';
		  }
		});
	  
		// Clear and repopulate the element
		element.innerHTML = '';
		newElements.forEach(el => element.appendChild(el));
	  
		element.style.opacity = '1';




		// Draw lines
		let currentLineTop = null;
		let startPoint = null;
		let endPoint = 0;

		const lineContainer = document.createElement('div');
		lineContainer.className = 'line-container';

		// Function to draw line
		const drawLineFunc = (start, end, top, parent) => {
			const width = end - start;
			const drawLineOuter = document.createElement('div');
			drawLineOuter.className = 'draw-line-container';
			drawLineOuter.style.width = `${width}px`;
			drawLineOuter.style.left = `${start}px`;
			drawLineOuter.style.top = `${top - parent.top}px`;

			const drawLine = document.createElement('div');
			drawLine.className = 'draw-line';
			drawLine.style.animationDuration = `${600 + width}ms`;

			drawLineOuter.appendChild(drawLine);
			lineContainer.appendChild(drawLineOuter);
			
		};

		newElements.forEach((el, index) => {

			// Get bounding rectangles
			const rect = el.getBoundingClientRect();
			const parentRect = element.getBoundingClientRect();
		
			// Check if this element is the start of a new line
			if (currentLineTop === null || currentLineTop !== rect.top) {
			
			// If there's a line to be drawn before starting a new one
			if (currentLineTop !== null && startPoint !== null) {
				drawLineFunc(startPoint, endPoint, currentLineTop, parentRect);
			}
			
			// Update the top position for the current line
			currentLineTop = rect.top;
			
			// Reset start point for a new line
			startPoint = null;
			}
		
			// Update start and end points only if it's not a 'keeper'
			if (!el.classList.contains('keeper')) {
			if (startPoint === null) {
				el.classList.add('start-line-draw');
				startPoint = rect.left - parentRect.left;
			}
			endPoint = rect.right - parentRect.left;
			}
		
			// Check the next element's position or if it's the last element
			const isLastElement = index === newElements.length - 1;
			const nextElement = newElements[index + 1];
			const isNextOnNewLine = nextElement ? (nextElement.getBoundingClientRect().top !== currentLineTop) : false;
		
			// If it's a 'keeper' or the last element or next element starts a new line, draw the line
			if (el.classList.contains('keeper') || isLastElement || isNextOnNewLine) {
			if (startPoint !== null) {
				endPoint = endPoint - 10;  // Adjust end point if needed
				drawLineFunc(startPoint, endPoint, currentLineTop, parentRect);
				// Reset start point for new line
				startPoint = null;
			}
			}
		});
		

		element.appendChild(lineContainer);



		let lineDrawTracker = 0;
	  
		// Type out each element

		const totalLength = ((newElements.length - 4) * 100) + 320 + 220 + 150;

		newElements.forEach((el, index) => {
			let delay;
			if(index === newElements.length - 1){
				delay = 320;
			} else if(index === newElements.length - 2){
				delay = 220;
			} else if(index === newElements.length - 3){
				delay = 150;
			} else {
				delay = 100;
			}
			
			totalDuration += delay;
		
			setTimeout(() => {
				el.style.opacity = '1';

				// if(el.classList.contains('start-line-draw')){
				// 	element.querySelectorAll('.draw-line-container')[lineDrawTracker++].classList.add('play-draw-line');
				// }

				// if (!el.classList.contains('keeper')) {
				// 	el.classList.add('fade-out-word');
				// }
		
				// Calculate the new height of the answer div based on the bottom of the newly visible element
				const rect = el.getBoundingClientRect();
				const parentRect = element.getBoundingClientRect();
				const newHeight = rect.bottom - parentRect.top;
				element.style.height = `${newHeight}px`;
		
			}, totalDuration);
		});


		setTimeout(() => {

			lineDrawTracker = 0;
			let lineDrawWait = 0;

			element.querySelectorAll('.answer span:not(.keeper)').forEach((span, index) => {

				setTimeout(() => {

					if(span.classList.contains('start-line-draw')){

						const currentLine = element.querySelectorAll('.draw-line-container')[lineDrawTracker++];
						
						currentLine.classList.add('play-draw-line');

					}
					
					span.classList.add('fade-out-word');

				}, (index * 160));
			});
		}, totalLength - 500);

		
		// Callback or further action can be scheduled here, if needed
		setTimeout(() => {
			element.style.opacity = '1';
			// element.style.height = 'auto'; // Reset to auto height
		}, totalDuration + 1000);
	}
	  
	  
	  
	


}